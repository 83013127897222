@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
@import url(https://fonts.googleapis.com/css?family=Encode+Sans+Expanded);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Encode Sans Expanded", sans-serif;
}

.logo-text {
  font: "Josefin Sans";

}

.center-align {
  text-align: center;
}

.black-bg {
  background: black;

}

.white-bg {
  background: white;

}

.primary-bg {
  background: #B05FFD;

}

.h1-hero {
  color: #ffffff;
  font-size: 44px;
  text-align: center;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }

}

.hero-text {
  font-size: 28px;
  text-align: center;
  font-style: oblique;

}

.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #3f3902 0%,
    #e2ebe9 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: textclip 5s linear infinite;
          animation: textclip 5s linear infinite;
  display: inline-block;
     
}

@-webkit-keyframes textclip {
  to {
    background-position: 200% center;
  }
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}



.img-wrap {
  height: 100%;
  max-width: 700px;
  margin: auto;
	transition: .1s ease-in-out;

}

.img-wrapceo {
  height: 100%;
  max-width: 700px;
  margin: auto;
	transition: .1s ease-in-out;

}


.img-wrap1 {
  height: 100%;
  max-width: 700px;
  margin: auto;
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
	transition: .3s ease-in-out;

}

.img-wrap:hover{
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
  
}

.img-wrap1:hover{
  /* height: 100%;
  max-width: 600px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f7eada;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

@media screen and (min-width: 480px){
.img-wrap2 {
  height: 100%;
  max-width: 700px;
  margin: auto;
	transition: .1s ease-in-out;
}
.img-wrap2:hover{
  height: 100%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f7eada;
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}
}


.fit-img {
  width: 100%;

}

.expand-row {
  width: 100%;
}

.white-text {
  color: white;
}

.black-text {
  color: black;
}

.gray-text {
  color: gray;
}

.pl {
  padding-left: 20px;
}

.pr {
  padding-right: 20px;
}



:root
{
  --yellow: #F5C32C;
    --orange : #bd7c1a;
    --black : #242D49;
    --gray : #788097;
    --blueCard : #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --orangeCard: rgba(252, 166, 31, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

/* .elem:hover {
  animation: moveToLeft 3s linear;
  animation-delay: 1ms;
}

@keyframes moveToLeft {
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(20px) translateY(20px) ;
  }

  75% {
    transform: translateX(0px) translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
} */





  
  .animated-button1 {
    background: linear-gradient(-30deg, #1CB5E0 50%, #0aa2cc 50%);
    padding: 12px 25px;
    margin-left: 70px;
    border-radius: 50px;
    display: inline-block;
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
    overflow: hidden;
    color: #fff;
    font-size: 15px;
    letter-spacing: 2.5px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  }
  
  .animated-button1::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #4206f6;
    opacity: 0;
    transition: .2s opacity ease-in-out;
  }
  
  .animated-button1:hover::before {
    opacity: 0.2;
  }
  
  .animated-button1 span {
    position: absolute;
  }
  
  .animated-button1 span:nth-child(1) {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 3px;
    background: linear-gradient(to left, rgba(43, 8, 8, 0), #bd7c1a);
    -webkit-animation: 2s animateTop linear infinite;
            animation: 2s animateTop linear infinite;
  }
  
  @-webkit-keyframes animateTop {
    0% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    }
    100% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
  }
  
  @keyframes animateTop {
    0% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    }
    100% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
  }
  
  .animated-button1 span:nth-child(2) {
    top: 0px;
    right: 0px;
    height: 100%;
    width: 3px;
    background: linear-gradient(to top, rgba(43, 8, 8, 0), #bd7c1a);
    -webkit-animation: 2s animateRight linear -1s infinite;
            animation: 2s animateRight linear -1s infinite;
  }
  
  @-webkit-keyframes animateRight {
    0% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
    }
    100% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }
  }
  
  @keyframes animateRight {
    0% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
    }
    100% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }
  }
  
  .animated-button1 span:nth-child(3) {
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 3px;
    background: linear-gradient(to right, rgba(43, 8, 8, 0), #bd7c1a);
    -webkit-animation: 2s animateBottom linear infinite;
            animation: 2s animateBottom linear infinite;
  }
  
  @-webkit-keyframes animateBottom {
    0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
    100% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    }
  }
  
  @keyframes animateBottom {
    0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
    100% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    }
  }
  
  .animated-button1 span:nth-child(4) {
    top: 0px;
    left: 0px;
    height: 100%;
    width: 3px;
    background: linear-gradient(to bottom, rgba(43, 8, 8, 0), #bd7c1a);
    -webkit-animation: 2s animateLeft linear -1s infinite;
            animation: 2s animateLeft linear -1s infinite;
  }
  
  @-webkit-keyframes animateLeft {
    0% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }
    100% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
    }
  }
  
  @keyframes animateLeft {
    0% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }
    100% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
    }
  }
:root
{
  --yellow: #F5C32C;
    --orange : #bd7c1a;
    --black : #242D49;
    --gray : #788097;
    --blueCard : #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --orangeCard: rgba(252, 166, 31, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}


  
  




/* actual css */
.Intro {
  display: flex;
  height: 100vh;
  margin-top: 6.5rem;
  padding: 0.5rem 6rem;
  overflow: hidden;
  color: #242D49;
  color: var(--black); 
}

/* left side styling */
.i-left {
  display: flex;
  position: relative;
  flex: 1 1;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
}
.i-name {
  display: flex;
  flex-direction: column;
  grid-gap: 0.25rem;
  gap: 0.25rem;
}
.i-name > :nth-child(1) {
  color: #bd7c1a;
  color: var(--orange);
  font-weight: bold;
  font-size: 3rem;
}
.i-name > :nth-child(2) {
  color: #129dc4;
  font-weight: bold;
  font-size: 3rem;
}
.i-name > :nth-child(3) {
  font-weight: 100;
  font-size: 14px;
  color: #788097;
  color: var(--gray);
  margin-top: 10px;
}
.i-button {
  background: linear-gradient(-30deg, #1CB5E0 50%, #0aa2cc 50%);
    box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
    border-radius: 34px;
    border: none;
    color: white;
    font-size: 16px; 
    width: 9rem;
    height:3rem;
    margin-top: 1.5rem;
}

.i-button:hover {
  background: white;
  cursor: pointer;
  border: 1px solid #bd7c1a;
  border: 1px solid var(--orange);
  color: #bd7c1a;
  color: var(--orange);
}
.i-icons {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}


/* right side styling */
.i-right {
  flex: 1 1;
  position: relative;
  margin-top: 4.5rem;
 
}


.i-right > * {
  position: absolute;
  z-index: 1;
}
.i-right > :nth-child(1) {
  -webkit-transform: scale(0.69);
          transform: scale(0.69);
  left: -15%;
  top: -6%;
}
.i-right > :nth-child(2) {
  top: -6rem;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  left: -5rem;
}
.i-right > :nth-child(3) {
  -webkit-transform: scale(0.83);
          transform: scale(0.83);
  left: 10%;
  top: -35%;
}
.i-right > :nth-child(4) {
  -webkit-transform: scale(0.32);
          transform: scale(0.32);
  top: -34%;
  left: -4%;
  border-radius: 50%;
  padding: 0;
}

/* blur */
.blur {
  position: absolute;
  width: 10rem;
  height: 14rem;
  border-radius: 50%;
  background: #edd0ff;
  -webkit-filter: blur(212px);
          filter: blur(212px);
  z-index: -9;
  top: -18%;
  left: 56%;
  -webkit-filter: blur(72px);
          filter: blur(72px);
}

@media screen and (max-width: 480px) {
  .Intro {
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    height: 75rem;
    padding: 0.5rem 3rem;
  }

  .i-button{
    margin-top: -1rem;
  }

  .i-right {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    left: -3.5rem;
  }
  .i-right .blur {
    display: none;
  }

  .i-right > :nth-child(1) {
    -webkit-transform: scale(0.64);
            transform: scale(0.64);
    left: -30%;
    top: -9%;
  }
  .i-right > :nth-child(2) {
    top: -5rem;
    -webkit-transform: scale(0.50);
            transform: scale(0.50);
    left: -5rem;
  }
  .i-right > :nth-child(3) {
    -webkit-transform: scale(0.83);
            transform: scale(0.83);
    left: 10%;
    top: -50%;
  }

  .floating-div:nth-of-type(1){
    /* display: none; */
    top: -9rem !important;;
  }
}

.floatingDiv {
  justify-content: space-around;
  background: white;
  box-shadow: var(--boxShadow);
  border-radius: 17px;
  display: flex;
  align-items: center;
  padding: 0px 28px 0px 0px;
  height: 4.5rem;
}
.floatingDiv > img {
  -webkit-transform: scale(0.4);
          transform: scale(0.4);
}
.floatingDiv > span {
  font-family: sans-serif;
  font-size: 16px;
  /* yahan ye color add kia hy bad mein dark mode k waqt */
  color: black;
}


.experience {
  display: flex;
  justify-content: flex-start;
  grid-gap: 5rem;
  gap: 5rem;
  /* height: 30vh; */
  /* margin-bottom: 8rem; */
  /* darkmode */
  color: var(--black);
}
.achievement{
    display: flex;
    flex-direction: column;    
    align-items: center;
}
.circle {
    width: 4rem;
    height: 4rem;
    background: white;
    border-radius: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    
}

.circle::before {
    border-radius: 100%;
    content: '';
    background-image: linear-gradient(to bottom, #87e6fb 0%, #ffc05c 100%);
    top: -8px;
    left: -8px;
    bottom: -8px;
    right: -8px;
    position: absolute;
    z-index:-1;
    box-shadow: var(--boxShadow);
}

.achievement span:nth-of-type(2){
    color: var(--orange);
}


@media screen and (max-width: 480px) {
    .experience{
        -webkit-transform: scale(0.7);
                transform: scale(0.7);
        margin-right: 20rem;
    }
}
:root
{
  --yellow: #F5C32C;
    --orange : #bd7c1a;
    --black : #242D49;
    --gray : #788097;
    --blueCard : #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --orangeCard: rgba(252, 166, 31, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}



/* actual css */
.works {
  /* padding: 0 3rem 0 3rem; */
  display: flex;
  height: 100vh;
  margin-top: 1rem;
  padding: 0.5rem 6rem;
  color: #242D49;
  color: var(--black); 

  /* scroll */
  align-items: center;
}
/* left side */
.awesome {
  display: flex;
  flex-direction: column;
  position: relative;
}

.awesome > :nth-child(1) {
  color: #bd7c1a;
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
}
.awesome > :nth-child(2) {
  color: #129dc4;
  font-size: 2.5rem;
  font-weight: bold;
}
.awesome > :nth-child(3) {
  color: #788097;
  color: var(--gray);
  font-size: 14px;
  margin-top: 1rem;
}
.s-button {
  background: linear-gradient(-30deg, #1CB5E0 50%, #0aa2cc 50%);
  box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
  border-radius: 34px;
  border: none;
  color: white;
  font-size: 16px;
  width: 9rem;
  height:3rem;
  margin-top: 3rem;
}
.s-button:hover {
  background: white;
  cursor: pointer;
  border: 1px solid #bd7c1a;
  border: 1px solid var(--orange);
  color: #bd7c1a;
  color: var(--orange);
}

/* right side */
.w-right {
  position: relative;
  left: 10rem;
  height: 100vh;
 margin-top: 20rem;
 
}

.w-mainCircle {
  left: 9rem;
  position: relative;
  width: 19rem;
  height: 19rem;
  border-radius: 100%;
  /* box-shadow: var(--smboxShadow); */
  top: 1rem;
  background: white;
  /* darkMode */
  z-index: 2;
}
.w-mainCircle > * {
  position: absolute;
  
}
.w-mainCircle > :nth-child(1) {
  top: 2.7rem;
  left: 5rem;
}
.w-mainCircle > :nth-child(2) {
  left: -2.5rem;
  top: 2.7rem;
}
/* .w-mainCircle > :nth-child(3) {
  left: 5rem;
  top: 5rem;
} */
.w-mainCircleimg{
  width: 140px;
  height: 100px;
}
.w-mainCircleimgstc{
  width: 140px;
  height: 100px;
}

.w-mainCircleimgstcs{
  width: 140px;
  height: 100px;
}

.w-mainCircleimgdawiyat{
  width: 140px;
  height: 100px;
}

.w-mainCircleimgpic{
  width: 150px;
  height: 70px;
}

.w-mainCircle > :nth-child(3) {
  left: 12.5rem;
  top: 2.7rem;
}
.w-mainCircle > :nth-child(4) {
  left: 20rem;
  top: 2.7rem;
}

.w-mainCircle > :nth-child(5) {
  left: 13rem;
  top: 11rem;
}
.w-mainCircle > :nth-child(6) {
  left: -2.5rem;
  top: 11rem;
}

.w-mainCircle > :nth-child(7) {
  left: 5.5rem;
  top: 17rem;
}

.w-mainCircle > :nth-child(8) {
  left: 5.5rem;
  top: -3rem;
}


.w-secCircle {
  width: 7rem;
  height: 7rem;
  position: absolute;
  border-radius: 100%;
  border: 5px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: center;
  background: "none";
  /* box-shadow: var(--smboxShadow); */
  background: white;
}

.w-secCircle > img {
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
}

.w-backCircle {
  position: absolute;
  height: 13rem;
  width: 13rem;
  border-radius: 100%;
  /* darkMode */
  z-index: 1;
}
.blueCircle {
  background: #1949b8;
  left: 18rem;
  top: 0rem;
}
.yellowCircle {
  background: #f5c32c;
  left: 18rem;
  top: 8rem;
}

@media screen and (max-width: 480px) {
  .works {
    flex-direction: column;
    height: 55rem;
    padding: 0.5rem 3rem;
    grid-gap: 5rem;
    gap: 5rem;
    margin-top: 4rem;
    margin-bottom: 10rem;
  }

  .w-right {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    margin-top: -3rem;
    margin-left: -2.5rem;
    left: 2rem;
  }
  .w-mainCircle{
    position: static;
  }
  .w-mainCircle > :nth-child(1) {
    top: -4rem;
    left: 5rem;
  }
  .w-mainCircle > :nth-child(4) {
    left: 5rem;
    top: 10rem;
  }
  .w-backCircle{
    left: 8rem!important;
  }
  
}

.job-container{width:70vw;background-color:#fff;display:flex;align-items:center;padding:1.5rem 2rem;border-radius:5px;margin:1.3rem 0;margin-top:3rem;position:relative;box-shadow:0px 4px 6px 4px rgba(91,164,164,.2)}@media only screen and (max-width: 375px){.job-container{flex-direction:column;align-items:flex-start;width:80vw;padding-bottom:1rem;margin-bottom:2rem}}.job-container .logo{display:flex;align-items:center;padding-right:1.5rem}@media only screen and (max-width: 375px){.job-container .logo{position:absolute;top:-2rem}.job-container .logo img{width:4rem;height:auto}}.job-container .part1{display:flex;flex-direction:column;flex-grow:1}@media only screen and (max-width: 375px){.job-container .part1{flex-grow:0;padding:1.3rem 0}}.job-container .part1 .company .cname{font-weight:700;color:var(--primary-color);margin-right:.8rem}.job-container .part1 .company .new,.job-container .part1 .company .featured{color:#fff;background-color:#5ba4a4;border-radius:1rem;padding:.4rem;font-size:.7rem;font-weight:700;text-transform:uppercase;margin-right:.8rem}.job-container .part1 .company .featured{background-color:#2c3a3a}.job-container .part1 .position{font-weight:700;font-size:1.1rem;display:inline-block;padding:.7rem 0}.job-container .part1 .position:hover{color:#5ba4a4}.job-container .part1 .details{color:#7b8e8e}.job-container .part2{display:flex;flex-wrap:wrap;justify-content:space-between}@media only screen and (max-width: 375px){.job-container .part2{border-top:1px solid #7b8e8e;padding-top:1rem;justify-content:flex-start}}.job-container .part2 span{background-color:#effafa;color:#5ba4a4;font-weight:700;padding:.5rem;border-radius:5px}.job-container .part2 span:not(:last-child){margin-right:1rem}.job-container .part2 span:hover{background-color:#5ba4a4;color:#fff}@media only screen and (max-width: 375px){.job-container .part2 span{margin-bottom:1rem}}.job-container--borderLeft{border-left:5px solid #5ba4a4}
.jobs{display:flex;flex-direction:column;align-items:center;margin-top:calc(2vw + 2rem);background-color:#f5fafa}
