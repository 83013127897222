:root
{
  --yellow: #F5C32C;
    --orange : #bd7c1a;
    --black : #242D49;
    --gray : #788097;
    --blueCard : #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --orangeCard: rgba(252, 166, 31, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}



/* actual css */
.works {
  /* padding: 0 3rem 0 3rem; */
  display: flex;
  height: 100vh;
  margin-top: 1rem;
  padding: 0.5rem 6rem;
  color: var(--black); 

  /* scroll */
  align-items: center;
}
/* left side */
.awesome {
  display: flex;
  flex-direction: column;
  position: relative;
}

.awesome > :nth-child(1) {
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
}
.awesome > :nth-child(2) {
  color: #129dc4;
  font-size: 2.5rem;
  font-weight: bold;
}
.awesome > :nth-child(3) {
  color: var(--gray);
  font-size: 14px;
  margin-top: 1rem;
}
.s-button {
  background: linear-gradient(-30deg, #1CB5E0 50%, #0aa2cc 50%);
  box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
  border-radius: 34px;
  border: none;
  color: white;
  font-size: 16px;
  width: 9rem;
  height:3rem;
  margin-top: 3rem;
}
.s-button:hover {
  background: white;
  cursor: pointer;
  border: 1px solid var(--orange);
  color: var(--orange);
}

/* right side */
.w-right {
  position: relative;
  left: 10rem;
  height: 100vh;
 margin-top: 20rem;
 
}

.w-mainCircle {
  left: 9rem;
  position: relative;
  width: 19rem;
  height: 19rem;
  border-radius: 100%;
  /* box-shadow: var(--smboxShadow); */
  top: 1rem;
  background: white;
  /* darkMode */
  z-index: 2;
}
.w-mainCircle > * {
  position: absolute;
  
}
.w-mainCircle > :nth-child(1) {
  top: 2.7rem;
  left: 5rem;
}
.w-mainCircle > :nth-child(2) {
  left: -2.5rem;
  top: 2.7rem;
}
/* .w-mainCircle > :nth-child(3) {
  left: 5rem;
  top: 5rem;
} */
.w-mainCircleimg{
  width: 140px;
  height: 100px;
}
.w-mainCircleimgstc{
  width: 140px;
  height: 100px;
}

.w-mainCircleimgstcs{
  width: 140px;
  height: 100px;
}

.w-mainCircleimgdawiyat{
  width: 140px;
  height: 100px;
}

.w-mainCircleimgpic{
  width: 150px;
  height: 70px;
}

.w-mainCircle > :nth-child(3) {
  left: 12.5rem;
  top: 2.7rem;
}
.w-mainCircle > :nth-child(4) {
  left: 20rem;
  top: 2.7rem;
}

.w-mainCircle > :nth-child(5) {
  left: 13rem;
  top: 11rem;
}
.w-mainCircle > :nth-child(6) {
  left: -2.5rem;
  top: 11rem;
}

.w-mainCircle > :nth-child(7) {
  left: 5.5rem;
  top: 17rem;
}

.w-mainCircle > :nth-child(8) {
  left: 5.5rem;
  top: -3rem;
}


.w-secCircle {
  width: 7rem;
  height: 7rem;
  position: absolute;
  border-radius: 100%;
  border: 5px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: center;
  background: "none";
  /* box-shadow: var(--smboxShadow); */
  background: white;
}

.w-secCircle > img {
  transform: scale(0.6);
}

.w-backCircle {
  position: absolute;
  height: 13rem;
  width: 13rem;
  border-radius: 100%;
  /* darkMode */
  z-index: 1;
}
.blueCircle {
  background: #1949b8;
  left: 18rem;
  top: 0rem;
}
.yellowCircle {
  background: #f5c32c;
  left: 18rem;
  top: 8rem;
}

@media screen and (max-width: 480px) {
  .works {
    flex-direction: column;
    height: 55rem;
    padding: 0.5rem 3rem;
    gap: 5rem;
    margin-top: 4rem;
    margin-bottom: 10rem;
  }

  .w-right {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    transform: scale(0.7);
    margin-top: -3rem;
    margin-left: -2.5rem;
    left: 2rem;
  }
  .w-mainCircle{
    position: static;
  }
  .w-mainCircle > :nth-child(1) {
    top: -4rem;
    left: 5rem;
  }
  .w-mainCircle > :nth-child(4) {
    left: 5rem;
    top: 10rem;
  }
  .w-backCircle{
    left: 8rem!important;
  }
  
}
