.jobs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: calc(2vw + 2rem);
  background-color: hsl(180, 33%, 97%);
}

// @import url("https://fonts.googleapis.com/css2?family=Spartan:wght@500;700&display=swap");

//variables





// html {
//   font-size: 15px;
//   font-weight: 500;
//   box-sizing: border-box;
// }

// body {
//   font-family: "Spartan", sans-serif;
//   background-color: var(--background-color);
// }

// @media only screen and (max-width: 375px) {
//   html {
//     font-size: 0.75rem;
//   }
// }


// //app css

// .header {
//   width: 100%;
//   height: 10rem;
//   // background-image: url(../images/bg-header-desktop.svg);
//   background-color: var(--primary-color);
// }

// //

// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

// // end here
