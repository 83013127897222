@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");
@import url("https://fonts.googleapis.com/css?family=Encode+Sans+Expanded");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Encode Sans Expanded", sans-serif;
}

.logo-text {
  font: "Josefin Sans";

}

.center-align {
  text-align: center;
}

.black-bg {
  background: black;

}

.white-bg {
  background: white;

}

.primary-bg {
  background: #B05FFD;

}

.h1-hero {
  color: #ffffff;
  font-size: 44px;
  text-align: center;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }

}

.hero-text {
  font-size: 28px;
  text-align: center;
  font-style: oblique;

}

.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #3f3902 0%,
    #e2ebe9 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 5s linear infinite;
  display: inline-block;
     
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}



.img-wrap {
  height: 100%;
  max-width: 700px;
  margin: auto;
	-webkit-transition: .1s ease-in-out;
	transition: .1s ease-in-out;

}

.img-wrapceo {
  height: 100%;
  max-width: 700px;
  margin: auto;
	-webkit-transition: .1s ease-in-out;
	transition: .1s ease-in-out;

}


.img-wrap1 {
  height: 100%;
  max-width: 700px;
  margin: auto;
  transform: scale(1.08);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;

}

.img-wrap:hover{
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
  
}

.img-wrap1:hover{
  /* height: 100%;
  max-width: 600px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f7eada;
  transform: scale(0.8);
}

@media screen and (min-width: 480px){
.img-wrap2 {
  height: 100%;
  max-width: 700px;
  margin: auto;
	-webkit-transition: .1s ease-in-out;
	transition: .1s ease-in-out;
}
.img-wrap2:hover{
  height: 100%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f7eada;
  transform: scale(1.3);
}
}


.fit-img {
  width: 100%;

}

.expand-row {
  width: 100%;
}

.white-text {
  color: white;
}

.black-text {
  color: black;
}

.gray-text {
  color: gray;
}

.pl {
  padding-left: 20px;
}

.pr {
  padding-right: 20px;
}



:root
{
  --yellow: #F5C32C;
    --orange : #bd7c1a;
    --black : #242D49;
    --gray : #788097;
    --blueCard : #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --orangeCard: rgba(252, 166, 31, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

/* .elem:hover {
  animation: moveToLeft 3s linear;
  animation-delay: 1ms;
}

@keyframes moveToLeft {
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(20px) translateY(20px) ;
  }

  75% {
    transform: translateX(0px) translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
} */



