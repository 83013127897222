.job-container {
  width: 70vw;
  background-color: #fff;

  display: flex;
  align-items: center;
  padding: 1.5rem 2rem;
  border-radius: 5px;

  margin: 1.3rem 0;
  margin-top:  3rem;
  position: relative;

  box-shadow: 0px 4px 6px 4px rgba(91, 164, 164, 0.2);

  @media only screen and (max-width: 375px) {
    flex-direction: column;
    align-items: flex-start;
    width: 80vw;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
  }

  .logo {
    display: flex;
    align-items: center;
    padding-right: 1.5rem;

    @media only screen and (max-width: 375px) {
      position: absolute;
      top: -2rem;
      img {
        width: 4rem;
        height: auto;
      }
    }
  }

  .part1 {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @media only screen and (max-width: 375px) {
      flex-grow: 0;
      padding: 1.3rem 0;
    }

    .company {
      .cname {
        font-weight: 700;
        color: var(--primary-color);
        margin-right: 0.8rem;
      }

      .new,
      .featured {
        color: #fff;
        background-color: rgb(91, 164, 164);
        border-radius: 1rem;
        padding: 0.4rem;
        font-size: 0.7rem;
        font-weight: 700;
        text-transform: uppercase;
        margin-right: 0.8rem;
      }

      .featured {
        background-color: hsl(180, 14%, 20%);
      }
    }

    .position {
      font-weight: 700;
      font-size: 1.1rem;
      display: inline-block;
      padding: 0.7rem 0;
      // cursor: pointer;
      &:hover {
        color:  rgb(91, 164, 164);
      }
    }

    .details {
      color: hsl(180, 8%, 52%);
    }
  }

  .part2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media only screen and (max-width: 375px) {
      border-top: 1px solid hsl(180, 8%, 52%);
      padding-top: 1rem;
      justify-content: flex-start;
    }

    span {
      &:not(:last-child) {
        margin-right: 1rem;
      }

      &:hover {
        background-color: rgb(91, 164, 164);
        color: #ffffff;
      }

      @media only screen and (max-width: 375px) {
        margin-bottom: 1rem;
      }

      background-color: hsl(180, 52%, 96%);
      color: rgb(91, 164, 164);
      font-weight: 700;
      padding: 0.5rem;
      border-radius: 5px;
      // cursor: pointer;
    }
  }

  &--borderLeft {
    border-left: 5px solid rgb(91, 164, 164);
  }
}
