:root
{
  --yellow: #F5C32C;
    --orange : #bd7c1a;
    --black : #242D49;
    --gray : #788097;
    --blueCard : #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --orangeCard: rgba(252, 166, 31, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}


  
  




/* actual css */
.Intro {
  display: flex;
  height: 100vh;
  margin-top: 6.5rem;
  padding: 0.5rem 6rem;
  overflow: hidden;
  color: var(--black); 
}

/* left side styling */
.i-left {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  gap: 2rem;
}
.i-name {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.i-name > :nth-child(1) {
  color: var(--orange);
  font-weight: bold;
  font-size: 3rem;
}
.i-name > :nth-child(2) {
  color: #129dc4;
  font-weight: bold;
  font-size: 3rem;
}
.i-name > :nth-child(3) {
  font-weight: 100;
  font-size: 14px;
  color: var(--gray);
  margin-top: 10px;
}
.i-button {
  background: linear-gradient(-30deg, #1CB5E0 50%, #0aa2cc 50%);
    box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
    border-radius: 34px;
    border: none;
    color: white;
    font-size: 16px; 
    width: 9rem;
    height:3rem;
    margin-top: 1.5rem;
}

.i-button:hover {
  background: white;
  cursor: pointer;
  border: 1px solid var(--orange);
  color: var(--orange);
}
.i-icons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}


/* right side styling */
.i-right {
  flex: 1;
  position: relative;
  margin-top: 4.5rem;
 
}


.i-right > * {
  position: absolute;
  z-index: 1;
}
.i-right > :nth-child(1) {
  transform: scale(0.69);
  left: -15%;
  top: -6%;
}
.i-right > :nth-child(2) {
  top: -6rem;
  transform: scale(0.5);
  left: -5rem;
}
.i-right > :nth-child(3) {
  transform: scale(0.83);
  left: 10%;
  top: -35%;
}
.i-right > :nth-child(4) {
  transform: scale(0.32);
  top: -34%;
  left: -4%;
  border-radius: 50%;
  padding: 0;
}

/* blur */
.blur {
  position: absolute;
  width: 10rem;
  height: 14rem;
  border-radius: 50%;
  background: #edd0ff;
  filter: blur(212px);
  z-index: -9;
  top: -18%;
  left: 56%;
  filter: blur(72px);
}

@media screen and (max-width: 480px) {
  .Intro {
    flex-direction: column;
    gap: 1rem;
    height: 75rem;
    padding: 0.5rem 3rem;
  }

  .i-button{
    margin-top: -1rem;
  }

  .i-right {
    transform: scale(0.7);
    left: -3.5rem;
  }
  .i-right .blur {
    display: none;
  }

  .i-right > :nth-child(1) {
    transform: scale(0.64);
    left: -30%;
    top: -9%;
  }
  .i-right > :nth-child(2) {
    top: -5rem;
    transform: scale(0.50);
    left: -5rem;
  }
  .i-right > :nth-child(3) {
    transform: scale(0.83);
    left: 10%;
    top: -50%;
  }

  .floating-div:nth-of-type(1){
    /* display: none; */
    top: -9rem !important;;
  }
}
